import React from "react";
import AEMTextCustom, { AEMTextCustomProps } from "component-text-custom";
import { createStyles, makeStyles } from "utils/material-ui-core";
import { splitFont } from "aem/ui/authoring/rh/FontPicker2";

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      "& p": {
        margin: 0
      }
    }
  })
);

export const TextCustom3: React.FC<AEMTextCustomProps> = props => {
  const classes = useStyles();
  const [fontXS, styleXS, weightXS] = splitFont(props.fontXS);
  const [fontSM, styleSM, weightSM] = splitFont(props.fontSM);
  const [fontMD, styleMD, weightMD] = splitFont(props.fontMD);
  const [fontLG, styleLG, weightLG] = splitFont(props.fontLG);
  const [fontXL, styleXL, weightXL] = splitFont(props.fontXL);
  const newProps = {
    ...props,
    fontXS,
    styleXS,
    weightXS,
    fontSM,
    styleSM,
    weightSM,
    fontMD,
    styleMD,
    weightMD,
    fontLG,
    styleLG,
    weightLG,
    fontXL,
    styleXL,
    weightXL,
    className: classes.root
  };
  return <AEMTextCustom {...newProps} isV3={true} />;
};

export type { AEMTextCustomProps };
export default TextCustom3;
