import classNames from "classnames";
import he from "he";
import React, { FC } from "react";
import { RHReferenceableComponentProps } from "types";
import { RHAnimatableComponentProps } from "utils/animation/types";
import { useAnimation } from "utils/animation/useAnimation";
import { createStyles, makeStyles, Theme } from "utils/material-ui-core";

export type TextContent = string[] | string | undefined | null;

const removeProblematicUnicode = (text: string) => {
  return text.replace(/\u2028/g, "");
};

export function getContent(text: TextContent): string {
  if (text === null || text === undefined) return "";
  else if (typeof text === "string") return removeProblematicUnicode(text);
  else {
    return text
      .map(w => w?.trim())
      .filter(w => w?.length > 0)
      .map(removeProblematicUnicode)
      .join(" ");
  }
}

const useStyles = makeStyles((theme: Theme) => {
  // const base = theme.overrides?.MuiTypography;

  return createStyles({
    root: {
      // ...base?.body1,
      // "& h1": { ...base?.h1 },
      // "& h2": { ...base?.h2 },
      // "& h3": { ...base?.h3 },
      // "& h4": { ...base?.h4 },
      // "& h5": { ...base?.h5 },
      // "& h6": { ...base?.h6 },
      // "& subtitle1": { ...base?.subtitle1 },
      // "& subtitle2": { ...base?.subtitle2 },
      // "& body1": { ...base?.body1 },
      // "& body2": { ...base?.body2 },
      // "& caption": { ...base?.caption },
      // "& button": { ...base?.button },
      // "& overline": { ...base?.overline },
      "& a:hover": {
        color: "#cccccc"
      },
      "& a": {
        transition: "all 1s ease"
      },
      "& h2": {
        lineHeight: "1.2"
      }
    }
  });
});

export type TextProps = React.HTMLAttributes<HTMLDivElement> &
  RHAnimatableComponentProps &
  RHReferenceableComponentProps & {
    text: TextContent;
  };

export const Text: FC<TextProps> = ({
  domRefCallback,
  extraHTMLAttributes = {},
  rhUid,
  rhAnimation,
  className,
  text,
  ...rest
}) => {
  useAnimation({ rhUid, rhAnimation });

  const classes = useStyles();

  return (
    <div
      id={rest?.id || "component-text"}
      {...extraHTMLAttributes}
      {...rest}
      ref={domRefCallback}
      className={classNames(
        extraHTMLAttributes?.className,
        classes.root,
        className
      )}
      dangerouslySetInnerHTML={{
        __html: he.decode(getContent(text || "Enter Text"))
      }}
    />
  );
};

export default Text;
