import React, {
  CSSProperties,
  ReactNode,
  useEffect,
  useRef,
  useState
} from "react";
import memoize from "utils/memoize";

interface AccordionDetailsProps {
  children: ReactNode;
  isOpen?: boolean;
  classes?: { [key: string]: string };
  style?: CSSProperties;
}

const AccordionDetails: React.FC<AccordionDetailsProps> = ({
  children,
  isOpen,
  classes,
  style = {}
}) => {
  const contentRef = useRef<HTMLDivElement>(null);
  const [height, setHeight] = useState<string | undefined>(undefined);

  useEffect(() => {
    if (isOpen && contentRef.current) {
      setHeight(`${contentRef.current.scrollHeight}px`);
    } else {
      setHeight("0px");
    }
  }, [isOpen]);

  return (
    <div
      className={`overflow-hidden ${classes?.root ?? ""} ${
        isOpen && (classes?.expended ?? "")
      }`}
      ref={contentRef}
      style={{
        height,
        transition: "height 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
        transitionDuration: "303ms",
        ...style
      }}
      data-testid={"accordion-details"}
    >
      <div className={`${classes?.content ?? ""}`}>{children}</div>
    </div>
  );
};

export default memoize(AccordionDetails);
