import useBrand from "hooks-use-brand/useBrand";
import React, { FC, useLayoutEffect, useState } from "react";
import { useCookies } from "hooks/useCookies";
import { shouldReloadForATG } from "utils/shouldReloadForATG";
import memoize from "utils/memoize";
import useIsoRedirect from "hooks-use-isoredirect";

export interface ReloadForATGControllerProps {}

export const ReloadForATGController: FC<ReloadForATGControllerProps> = ({
  children
}) => {
  const brand = useBrand();
  const [reloading, setReloading] = useState(false);
  const [cookies] = useCookies(["PF_EXP"]);
  const { pathname, search, hash } = window.location;

  useLayoutEffect(() => {
    if (shouldReloadForATG(cookies, { pathname, search, hash, brand })) {
      useIsoRedirect(
        `${pathname || ""}${search || ""}${hash || ""}`,
        "windowAssign"
      );
      setReloading(true);
    }
  }, [brand, setReloading, cookies, pathname, search, hash]);

  return reloading ? null : <>{children}</>;
};

ReloadForATGController.defaultProps = {};

export default memoize(ReloadForATGController);
