import React from "react";
import classNames from "classnames";
import { TailwindSkeleton } from "component-tailwind-skeletons";
import { MultilineSkeleton } from "skeleton-multi-line";

import useRHStyles from "hooks/useRHStyles";

export const TextSkeleton = () => {
  const rhClasses = useRHStyles({
    keys: ["doubleBottomMargin", "bottomMargin", "tripleBottomMargin"]
  });

  return (
    <div className="flex flex-col w-full">
      <div className={classNames(rhClasses.doubleBottomMargin)}>
        <TailwindSkeleton variant="text" width={"200px"} height={"36px"} />
      </div>
      <div className={classNames(rhClasses.doubleBottomMargin)}>
        <MultilineSkeleton lines={5} />
      </div>
      <div className={classNames(rhClasses.bottomMargin)}>
        <MultilineSkeleton lines={4} />
      </div>
      <div className={classNames(rhClasses.tripleBottomMargin)}>
        <TailwindSkeleton variant="text" width={"100px"} height={"40px"} />
      </div>
      <div className={classNames(rhClasses.doubleBottomMargin)}>
        <TailwindSkeleton variant="text" width={"200px"} height={"36px"} />
      </div>
      <div className={classNames(rhClasses.doubleBottomMargin)}>
        <MultilineSkeleton lines={4} />
      </div>
    </div>
  );
};
