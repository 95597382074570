import { Skeleton } from "@material-ui/lab";
import { useEnv } from "hooks/useEnv";
import React from "react";
import yn from "yn";

interface SkeletonProps {
  id?: string;
  variant?: "text" | "rect" | "circle";
  width?: string | number;
  height?: string | number;
  animation?: "pulse" | "wave" | false;
  className?: string;
  style?: React.CSSProperties;
}

export const TailwindSkeleton: React.FC<SkeletonProps> = ({
  id = "",
  variant = "text",
  width,
  height,
  animation = "pulse",
  className = "",
  style = {},
  ...rest
}) => {
  const env = useEnv();
  const FEATURE_TAILWIND_COMPONENTS = yn(env.FEATURE_TAILWIND_COMPONENTS);

  if (!FEATURE_TAILWIND_COMPONENTS) {
    return (
      <Skeleton
        className={className}
        variant={variant}
        width={width}
        height={height}
        animation={animation}
        id={id}
        data-testid={"skeleton"}
        style={{ ...(style ?? {}) }}
        {...rest}
      />
    );
  }

  const baseClasses = "bg-[#F1EFEC]";
  const variantClasses =
    variant === "text"
      ? "transform scale-x-100 scale-y-[60%] rounded my-0 origin-x-0 origin-y-[60%]"
      : variant === "rect"
      ? "rounded-md"
      : "rounded-full";
  const animationClasses =
    animation === "pulse"
      ? "animate-pulse"
      : animation === "wave"
      ? "bg-gradient-to-r from-gray-300 via-gray-200 to-gray-300 animate-wave"
      : "";

  return (
    <span
      className={`h-[1.43em] w-full block ${baseClasses} ${variantClasses} ${animationClasses} ${className}`}
      id={id}
      data-testid={"tailwind-skeleton"}
      style={{
        ...(height && { height: height }),
        ...(width && { width: width }),
        ...(style && { ...style })
      }}
      {...rest}
    ></span>
  );
};

export default TailwindSkeleton;

export * from "./TextSkeleton";
export * from "./TwoColumnsSkeleton";
