import { SvgIconProps } from "@material-ui/core/SvgIcon";
import { KeyboardArrowDown } from "@material-ui/icons";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import classNames from "classnames";
import Drawer from "@RHCommerceDev/component-drawer";
import { usePageContent } from "custom-providers/LocationProvider";
import he from "he";
import { useAppId } from "@RHCommerceDev/hooks-use-app-id";
import useBrand, {
  BRAND_CODE,
  BRAND_NAME_INTERIORS,
  BRAND_NAME_OUTDOOR,
  brandCodeToHTMLTitle
} from "@RHCommerceDev/hooks-use-brand/useBrand";
import useButtonStyles from "@RHCommerceDev/hooks/useButtonStyles";
import { useCookiesWithPermission } from "@RHCommerceDev/hooks/useCookiesWithPermission";
import { useEnv } from "@RHCommerceDev/hooks/useEnv";
import { useFetchModel } from "@RHCommerceDev/hooks/useFetchModel";
import { useIsoCookies } from "@RHCommerceDev/hooks/useIsoCookies";
import { useParams2 } from "@RHCommerceDev/hooks/useParams";
import { processEnvServer } from "@RHCommerceDev/hooks/useSsrHooks";
import useTypographyStyles from "@RHCommerceDev/hooks/useTypographyStyles";
import RHArrowIcon from "@RHCommerceDev/icon-arrow";
import RHCloseIcon from "@RHCommerceDev/icon-close";
import { template } from "lodash";
import React, {
  FC,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import {
  CLOSE_MENU,
  Filter_by,
  REMOVE_FILTER
} from "@RHCommerceDev/resources/rhr-product-gallery-resource.json";
import { handleKeyboardPressWithEvent } from "@RHCommerceDev/utils/accessibility";
import {
  BRAND_NAME_TO_BRAND_CODE,
  BREAKPOINT_MD,
  BREAKPOINT_SM
} from "@RHCommerceDev/utils/constants";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  ClickAwayListener,
  createStyles,
  Grid,
  IconButton,
  Link,
  List,
  ListItem,
  makeStyles,
  Popper,
  Portal,
  SvgIcon,
  Theme,
  Typography,
  useMediaQuery
} from "@RHCommerceDev/utils/material-ui-core";
import {
  TailwindAccordion,
  TailwindAccordionSummary,
  TailwindAccordionDetails
} from "@RHCommerceDev/component-tailwind-accordion";
import memoize from "@RHCommerceDev/utils/memoize";
import yn from "yn";
import RefinementOptionData from "./RefinementOptionData";
import { getReqContext } from "@RHCommerceDev/utils/reqContext";
import { getSelectorsByUserAgent } from "react-device-detect";
import TailwindPopper from "@RHCommerceDev/component-tailwind-popper";
import TailwindDrawer from "@RHCommerceDev/component-tailwind-drawer";
import { removeNttSearchParams } from "utils/formatSearchUrl";
import RHCloseIconUpdated from "icons/RHCloseIconUpdated";
import RHSpinner from "@RHCommerceDev/component-rh-spinner";

const DISABLED_HINT_SCROLL_X = "disabled_hint_scroll_x";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    scrollXContainer: {
      marginRight: 30,
      position: "relative",
      flexWrap: "nowrap",
      overflow: "visible",
      "-ms-overflow-style": "visible",
      "scrollbar-width": "none",
      "&::-webkit-scrollbar": {
        display: "none"
      },
      "& p": {
        whiteSpace: "nowrap"
      }
    },

    refinementGroupTitle: {
      "&.sort": {
        marginLeft: 18,
        [theme.breakpoints.up(BREAKPOINT_SM)]: {
          marginLeft: 30
        }
      }
    },

    drawerMenu: {
      background: "rgba(0,0,0,0.6)",
      "& > .MuiBackdrop-root": {
        background: "none !important"
      }
    },

    booleanRefinement: {
      "& .MuiCheckbox-root": {
        marginLeft: `0 !important`,
        backgroundColor: "transparent !important"
      }
    },

    mobileBooleanRefinement: {
      "&:last-of-type": {
        borderBottom: "none"
      },

      "& .MuiCheckbox-root": {
        marginLeft: `0 !important`,
        marginTop: `0 !important`,
        marginBottom: `0 !important`,
        backgroundColor: "transparent !important"
      }
    }
  })
);

const useStylesAccordion = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      "&:first-child": {
        borderTop: "0.5px solid #CCCCCC"
      },
      "&:last-child": {
        borderBottom: "0.5px solid #CCCCCC"
      }
    },
    expanded: {
      margin: "0 !important",
      borderTop: "0.5px solid #CCCCCC",
      borderBottom: "0.5px solid #CCCCCC",
      "& + $expanded": {
        borderTop: "none"
      }
    }
  })
);

const useStylesAccordionSummary = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: "0px",
      backgroundColor: "#F9F7F4 !important",
      display: "flex !important",
      [theme.breakpoints.up(BREAKPOINT_MD)]: {
        backgroundColor: "#F9F7F4 !important"
      },
      paddingTop: "0px"
    },
    expandIcon: {
      "& svg": {
        fontSize: "14px",
        color: "#808080"
      }
    },
    content: {
      margin: "24px 0px",
      "&:nth-child(1), &:nth-child(2)": {
        margin: "24px 0px"
      },
      [theme.breakpoints.up("md")]: {
        margin: "21px 0px"
      },
      "&.Mui-expanded": {
        marginBottom: "34px"
      }
    }
  })
);

const useStylesAccordionDetail = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "block",
      backgroundColor: "#F9F7F4 !important",
      [theme.breakpoints.up(BREAKPOINT_MD)]: {
        backgroundColor: "#F9F7F4 !important"
      },
      padding: "0 0 32px 0",
      "& > div": {
        padding: "0px"
      }
    }
  })
);

interface SelectedRefinementButtonsProps {
  refinements: SearchRefinementLink[];
  showResetRefinement: Boolean;
  resetRefinement?: (event: React.MouseEvent) => void;
  changeNavState?: (navState: string, avoidSaleParam?: boolean) => void;
}

export const SelectedRefinementButtons: FC<SelectedRefinementButtonsProps> =
  memoize(
    ({ refinements, showResetRefinement, resetRefinement, changeNavState }) => {
      const { pathname } = useLocation();
      const isAemPage = !pathname?.includes(".jsp");
      const { pageContent } = !isAemPage
        ? usePageContent()
        : useFetchModel("/admin/products", false, false);
      const typographyStyles = useTypographyStyles({
        keys: ["rhBaseCaption"] // font size is added inline for SSR for facet name
      });
      const buttonStyles = useButtonStyles({
        keys: ["unstyledBtn"]
      });

      const isMobileOrTablet = useMediaQuery<Theme>(theme =>
        theme.breakpoints.down("md")
      );

      const isSelected = useMemo(() => {
        return refinements?.map(data => data?.isSelected);
      }, [refinements]);

      return (
        <>
          {refinements.map((option, index) => {
            const optionLabel = he.decode(
              (option?.localeLabel || option?.label)?.toLowerCase()
            );

            return (
              <Button
                key={`selected-option-${index}`}
                id={`refineMenuDropdown_clear-${option.label}-btn`}
                className="px-3.5 py-1 rounded-[32px] bg-white-300 mr-2 mb-2 flex-shrink-0"
                role="group"
              >
                <Typography
                  className={classNames(
                    typographyStyles.rhBaseCaption,
                    "!capitalize"
                  )}
                  role="button"
                  aria-label={optionLabel}
                >
                  {optionLabel}
                  {option?.refinementGroup && (
                    <span className="!ml-1">{option?.refinementGroup}</span>
                  )}
                </Typography>
                <button
                  className={classNames(buttonStyles.unstyledBtn, "!p-0")}
                  aria-label={template(
                    pageContent?.Remove_Filter || REMOVE_FILTER
                  )({
                    optionFilter: optionLabel
                  })}
                  onClick={() => changeNavState(option?.navigationState, true)}
                >
                  <RHCloseIcon className="!w-2 !ml-2" />
                </button>
              </Button>
            );
          })}
          {!isMobileOrTablet && (
            <button
              className={classNames(buttonStyles.unstyledBtn, "!p-0")}
              onClick={e => resetRefinement(e)}
              aria-label={pageContent?.CLEAR_ALL}
            >
              <Typography
                data-testid="refineMenuDropdown_clearAll-btn"
                role="button"
                aria-label={pageContent?.CLEAR_ALL}
                className={classNames(
                  typographyStyles.rhBaseCaption,
                  "cursor-pointer ml-2 mb-2 underline decoration-darkGray-500 underline-offset-[2px]",
                  {
                    block: showResetRefinement || isSelected,
                    hidden: !(showResetRefinement || isSelected)
                  }
                )}
              >
                {pageContent?.CLEAR_ALL}
              </Typography>
            </button>
          )}
        </>
      );
    }
  );

export const RHRArrowIcon: FC<SvgIconProps> = props => {
  return (
    <SvgIcon width="4" height="7" viewBox="0 0 4 7" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.86994 3.19348L0.757519 0.125342C0.576316 -0.042201 0.299183 -0.042201 0.12864 0.125342C-0.0419034 0.292886 -0.0419034 0.575615 0.12864 0.743159L2.93195 3.49716L0.12864 6.25115C-0.0419034 6.42917 -0.0419034 6.70143 0.12864 6.86897C0.299183 7.03651 0.576316 7.03651 0.757519 6.86897L3.86994 3.8113C4.04048 3.63329 4.04048 3.36103 3.86994 3.19348Z"
        fill="black"
      />
    </SvgIcon>
  );
};

interface BrandsRefinementProps {
  resultTabs: SearchResultTab[];
  pathname: string;
}
export const BrandsRefinement: FC<BrandsRefinementProps> = ({
  resultTabs,
  pathname
}) => {
  const env = useEnv();
  const FEATURE_TAILWIND_COMPONENTS = yn(env.FEATURE_TAILWIND_COMPONENTS);
  const brand = useBrand();
  const classes = useStyles();
  const { params } = useParams2({
    fromNav: "",
    site: brand,
    fromCG: ""
  });

  const { setCookieWrapper } = useCookiesWithPermission();

  const isAemPage = !pathname?.includes(".jsp");
  const { pageContent } = !isAemPage
    ? usePageContent()
    : useFetchModel("/admin/products", false, false);
  let brandCode = params?.site || brand;

  if (params?.site !== brand) {
    brandCode =
      brandCode === BRAND_NAME_INTERIORS
        ? BRAND_CODE?.INTERIORS
        : brandCode === BRAND_NAME_OUTDOOR
        ? BRAND_CODE?.OUTDOOR
        : brand;
  }

  const currentBrandTitle = `${brandCodeToHTMLTitle[brandCode]}`;
  const [anchorRef, setAnchorRef] = useState<HTMLDivElement | null>(null);
  const typographyStyles = useTypographyStyles({
    keys: ["rhBaseLink1", "rhBaseCaption", "rhBaseBody2"]
  });
  const { searchText } = useParams<{ searchText: string }>();
  const searchPath = yn(env?.FEATURE_URL_CHANGE) ? searchText : "results.jsp";
  const isSearchPage = pathname?.includes(`/search/`);
  const isPGPage = pathname?.includes("/catalog/category/products.jsp");
  const mdUp = useMediaQuery<Theme>(theme => theme.breakpoints.up("md"));
  const classesAccordion = useStylesAccordion();
  const classesAccordionSummary = useStylesAccordionSummary();
  const classesAccordionDetail = useStylesAccordionDetail();
  const { isConcierge } = useAppId();
  const showBrands =
    isSearchPage && isConcierge
      ? true
      : (isSearchPage
          ? brand === "RH"
            ? true
            : false
          : isPGPage
          ? false
          : true) &&
        params?.fromNav !== "true" &&
        isConcierge;

  const handleBrandSelect = (item: SearchResultTab) => {
    const selectedBrandCode =
      BRAND_NAME_TO_BRAND_CODE[item?.displayName ?? "RH"];
    setCookieWrapper(
      "selected_brand",
      selectedBrandCode === BRAND_NAME_INTERIORS
        ? BRAND_CODE?.INTERIORS
        : selectedBrandCode,
      {
        expires: new Date(2147483647000)
      }
    );
  };

  const BrandLinkList = (
    <List disablePadding>
      <ListItem disableGutters>
        <Typography
          className={typographyStyles.rhBaseBody2}
          data-testid="brand-title"
        >
          {currentBrandTitle}
        </Typography>
      </ListItem>

      {resultTabs.map((item, index) => {
        const navState = removeNttSearchParams(
          item?.link?.navigationState ?? ""
        );
        return (
          <ListItem disableGutters key={`brand-link`}>
            <Link
              data-testid={`brand-link-${index}`}
              key={`resultTab-${item.displayName}`}
              underline="none"
              component="a"
              href={`${item?.link?.host}/${searchPath}${navState}`}
              data-analytics-id="link"
              className="flex items-center"
              onClick={() => {
                handleBrandSelect(item);
              }}
            >
              <Typography
                dangerouslySetInnerHTML={{
                  __html: `${item.displayName}`
                }}
                className={typographyStyles.rhBaseBody2}
              />

              <RHArrowIcon className="!text-[8px] !w-2 !h-2 !ml-[3px] !text-black" />
            </Link>
          </ListItem>
        );
      })}
    </List>
  );
  const handleAnchorRef = useCallback(value => {
    setAnchorRef(value);
  }, []);

  if (!showBrands || isConcierge) return null;
  return mdUp ? (
    <Grid item>
      <button
        className="relative top-[1px] flex items-center cursor-pointer bg-transparent border-none mr-[30px] lg:!mr-10 xl:mr-[60px]"
        onClick={e => {
          handleAnchorRef(e.currentTarget);
        }}
        aria-label={pageContent?.CONCEPTS}
      >
        <Typography
          className={classNames([
            typographyStyles.rhBaseCaption,
            classes.refinementGroupTitle,
            "!text-[11px] select-none whitespace-nowrap uppercase "
          ])}
        >
          {pageContent?.CONCEPTS}
        </Typography>
        <KeyboardArrowDown
          className={`text-darkGray-500 active:rotate-180 ${
            !!anchorRef && "active"
          } !text-[12px]`}
        />
      </button>
      <Popper
        open={!!anchorRef}
        anchorEl={anchorRef}
        placement="bottom-start"
        modifiers={{
          flip: {
            enabled: false
          },
          preventOverflow: {
            enabled: false
          }
        }}
        className="py-10 px-8 pt-2 -mt-5 shadow-popperBox min-w-[300px] max-h-[650px] overflow-y-auto z-[9999] bg-white-300"
      >
        <ClickAwayListener
          onClickAway={() => {
            handleAnchorRef(null);
          }}
        >
          {BrandLinkList}
        </ClickAwayListener>
      </Popper>
    </Grid>
  ) : FEATURE_TAILWIND_COMPONENTS ? (
    <TailwindAccordion
      classes={{
        root: "relative border-0 border-t-[0.5px] border-solid border-gray-300",
        expanded: "border-0 border-b-[0.5px] border-solid border-gray-300"
      }}
    >
      <TailwindAccordionSummary
        expandIcon={<ExpandMoreIcon className={`text-[#808080] text-[14px]`} />}
        classes={{
          root: "p-0 bg-[#F9F7F4] relative before:content-[''] before:-top-[1px] before:left-0 before:right-0 before:h-[1px] before:opacity-100 transition-all before:absolute before:bg-[rgba(0,0,0,0.12)]",
          content: "my-[32px] md:my-[21px]",
          contentExpanded: "!mb-[34px]"
        }}
      >
        <div>
          <Typography
            className={classNames(
              typographyStyles.rhBaseCaption,
              "!uppercase !text-[11px] !leading-[13px] !tracking-[0.04em]"
            )}
          >
            {pageContent?.CONCEPTS}
          </Typography>
        </div>
      </TailwindAccordionSummary>

      <TailwindAccordionDetails
        classes={{
          content: "block bg-[#f9f7f4] pb-[32px] [&>div]:p-0"
        }}
      >
        <Typography
          className={classNames(typographyStyles.rhBaseBody2, "!mb-6")}
          data-testid="accordion-content"
        >
          {BrandLinkList}
        </Typography>
      </TailwindAccordionDetails>
    </TailwindAccordion>
  ) : (
    <Accordion classes={classesAccordion} square elevation={0}>
      <AccordionSummary
        classes={classesAccordionSummary}
        expandIcon={<ExpandMoreIcon />}
        data-testid="accordion-header"
      >
        <div>
          <Typography
            className={classNames(
              typographyStyles.rhBaseCaption,
              "!uppercase !text-[11px] !leading-[13px] !tracking-[0.04em]"
            )}
          >
            {pageContent?.CONCEPTS}
          </Typography>
        </div>
      </AccordionSummary>

      <AccordionDetails classes={classesAccordionDetail}>
        <Typography
          className={classNames(typographyStyles.rhBaseBody2, "!mb-6")}
          data-testid="accordion-content"
        >
          {BrandLinkList}
        </Typography>
      </AccordionDetails>
    </Accordion>
  );
};

interface SelectedRefineOptions {
  [key: string]: SearchRefinementLink[];
}

interface FacetData {
  [key: string]: SearchRefinementMenu[];
}
interface RefineSearchMenuProps {
  refinementMenus: SearchRefinementMenu[];
  sortMenu: SearchResultSortOption[];
  totalCount?: number;
  resultTabs?: Array<SearchResultTab> | null;
  resetRefinement?: (event: React.MouseEvent) => void;
  showResetRefinement?: Boolean;
  sizeFacets?: string[];
  materialFacets?: string[];
  selectedRefinementContainer?: HTMLDivElement | null;
  categoryId?: string;
  isSale?: boolean;
  loading?: boolean;
  booleanFacet?: SearchRefinementMenu[];
  version?: string;
  activeTab?: TabInfo;
  upholsteryFacets?: string[];
  dynamicFacet?: dynamicFacet[] | null;
  isNewConceptTabs?: boolean;
}

interface dynamicFacet {
  name: string;
  values: string[];
}

interface Breakpoint {
  name: string;
  range: [number, number];
}

const RefineSearchMenu: FC<RefineSearchMenuProps> = ({
  refinementMenus,
  sortMenu,
  totalCount,
  resultTabs,
  resetRefinement,
  showResetRefinement,
  selectedRefinementContainer,
  categoryId,
  isSale,
  loading,
  booleanFacet,
  version,
  activeTab,
  dynamicFacet,
  isNewConceptTabs
}) => {
  const { pathname } = useLocation();
  const isAemPage = !pathname?.includes(".jsp");
  const { pageContent } = !isAemPage
    ? usePageContent()
    : useFetchModel("/admin/products", false, false);
  const classes = useStyles();
  const history = useHistory();
  const { params } = useParams2({
    hasShellProducts: "false",
    site: "",
    fromCG: "",
    skipng: ""
  });
  const env = useEnv();
  const FEATURE_TAILWIND_COMPONENTS = yn(env.FEATURE_TAILWIND_COMPONENTS);
  const DefaulPopper = FEATURE_TAILWIND_COMPONENTS ? TailwindPopper : Popper;
  const DrawerComponent = FEATURE_TAILWIND_COMPONENTS ? TailwindDrawer : Drawer;
  const classesAccordion = useStylesAccordion();
  const classesAccordionSummary = useStylesAccordionSummary();
  const classesAccordionDetail = useStylesAccordionDetail();
  const refAnchors = useRef<HTMLButtonElement[] | null[]>([]);
  let [facetData, setFacetData] = useState<FacetData>({});
  const [activeRefinement, setActiveRefinement] = useState<String | null>(null);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [selectedRefineOptions, setSelectedRefineOptions] =
    useState<SelectedRefineOptions>({});
  const { setCookieWrapper } = useCookiesWithPermission();
  const cookie = useIsoCookies([DISABLED_HINT_SCROLL_X]);
  const nextgenCookie = "true";
  const [showHint, setShowHint] = useState<Boolean>(false);
  const [isBusy, setIsBusy] = useState(true);
  const scrollableContainer = useRef<HTMLDivElement | null>(null);
  const buttonStyles = useButtonStyles({
    keys: ["primaryBlackBtn", "secondaryBtn", "unstyledBtn"]
  });
  const typographyStyles = useTypographyStyles({
    keys: ["rhBaseLink1", "rhBaseCaption", "rhBaseBody2"]
  });

  const smDown = useMediaQuery<Theme>(theme => theme.breakpoints.down("sm"));
  const between = useMediaQuery<Theme>(theme =>
    theme.breakpoints.between(992, 1201)
  );
  const mdUp =
    useMediaQuery<Theme>(theme => theme.breakpoints.up("md")) || !smDown;

  const req = getReqContext();
  let mobile = false;
  const userAgent = req && req?.headers["user-agent"];
  if (userAgent) {
    const { isMobile } = getSelectorsByUserAgent(userAgent);
    mobile = isMobile;
  }

  const hasShellProducts = useMemo(
    () => JSON?.parse(params?.hasShellProducts),
    [params?.hasShellProducts]
  );

  const getSortAsRefinement = useCallback(sortMenu => {
    return {
      name: "Sort",
      ancestors: [],
      dimensionName: "var.sort",
      displayName: "var.sort",
      multiSelect: false,
      numRefinementsToShowInitially: "10",
      refinements: sortMenu.map(option => {
        return {
          count: 0,
          isSelected: option.selected,
          label: option.label,
          navigationState: option.navigationState,
          multiSelect: false,
          siteState: {}
        };
      }),
      key: ""
    };
  }, []);

  const getFacets = newFacetList => {
    const facets: FacetData = newFacetList?.reduce((acc, curr) => {
      const bucketName = curr.name;

      if (bucketName) {
        if (isNextGen && curr?.childRefinements?.length) {
          acc[bucketName] = curr.childRefinements;
        } else {
          let dynamicFacetHandled = false;
          dynamicFacet?.forEach(facet => {
            if (!isNextGen && facet?.values?.includes(curr?.dimensionName)) {
              // Ensure all required refinements are non-empty
              // const facetValues = facet.values.filter(value =>
              //   newFacetList.some(
              //     item =>
              //       item.dimensionName === value &&
              //       item.refinements?.length! > 0
              //   )
              // );
              // REMOVED CHECK AS SOME FACETS CAN HAVE EMPTY VALUES
              // if (facetValues.length === facet.values.length) {
              acc[facet.name] = acc[facet.name] || [];
              if (curr?.refinements?.length! > 0) {
                acc[facet.name].push(curr);
                dynamicFacetHandled = true;
              }
            }
            // }
          });

          if (!dynamicFacetHandled) {
            const existingBucketRefinements = acc[bucketName] || [];
            acc[bucketName] = [...existingBucketRefinements, curr];
          }
        }
      }
      return acc;
    }, {});

    return facets;
  };

  const isNextGen = version === "nextgen" || nextgenCookie === "true";

  if (processEnvServer) {
    const combinedRefinements = !mdUp
      ? [...refinementMenus, getSortAsRefinement(sortMenu)]
      : refinementMenus;

    const newFacetList = [] as SearchRefinementMenu[];
    combinedRefinements?.filter(item => {
      newFacetList.push(item);
      return false;
    });

    facetData = getFacets(newFacetList);
  }

  useEffect(() => {
    const combinedRefinements = !mdUp
      ? [...refinementMenus, getSortAsRefinement(sortMenu)]
      : refinementMenus;

    const newFacetList = [] as SearchRefinementMenu[];
    combinedRefinements?.filter(item => {
      newFacetList.push(item);
      return false;
    });

    const facets = getFacets(newFacetList);
    setFacetData(facets);
  }, [refinementMenus, sortMenu, dynamicFacet, mdUp]);

  useEffect(() => {
    const selectedOptions = Object.entries(facetData).reduce(
      (acc, [facetName, facetRefinements]) => {
        acc[facetName] = facetRefinements.reduce((selected, curr) => {
          return [
            ...selected,
            ...curr
              ?.refinements!.filter(r => r.isSelected)
              .map(r => ({
                ...r,
                ...(facetRefinements.length > 1 && {
                  refinementGroup: curr.name
                })
              }))
          ];
        }, []);
        return acc;
      },
      {}
    );

    const selectedBooleanFacetOptions = booleanFacet?.reduce(
      (acc, { name, refinements }) => {
        acc[name as string] = refinements?.reduce(
          (selected, curr) =>
            curr.isSelected ? [...selected, curr] : selected,
          []
        );

        return acc;
      },
      {}
    );

    setSelectedRefineOptions({
      ...(selectedBooleanFacetOptions ?? {}),
      ...selectedOptions
    });
  }, [facetData, booleanFacet]);

  useEffect(() => {
    if (cookie[DISABLED_HINT_SCROLL_X] !== "true" && refinementMenus.length) {
      setTimeout(() => {
        handleScrollHint();
      }, 2000);
      window.addEventListener("resize", handleScrollHint);
    }
    return () => {
      if (showHint && refinementMenus.length)
        window.removeEventListener("resize", handleScrollHint);
    };
  }, [refinementMenus, showHint]);
  useEffect(() => {
    if (!loading) {
      updateStatusMessage(`${pageContent?.SEARCH_RESULTS} ${totalCount}`);
    }
  }, [totalCount, loading, pageContent]);

  useEffect(() => {
    // After page load, set isBusy state to false to enable announcements
    setIsBusy(false);
  }, []);

  const handleScrollHint = useCallback(() => {
    const clientWidth = scrollableContainer?.current?.clientWidth || 0;
    const scrollWidth = scrollableContainer?.current?.scrollWidth || 0;
    setShowHint(clientWidth < scrollWidth);
  }, []);

  const hideHint = useCallback(() => {
    setShowHint(false);
    setCookieWrapper(DISABLED_HINT_SCROLL_X, "true");
  }, [setCookieWrapper]);

  const changeNavState = useCallback(
    (navState, avoidSaleParam = false) => {
      // Remove sale param which is injected through onCheckboxChange from RefinementOptionData
      if (!avoidSaleParam) {
        navState = navState.replace(/&sale=true/, "");
      }
      if (pathname?.includes("/catalog/category/products.jsp")) {
        navState = navState.replace(/Ntt=undefined&/, "");
        // navState = navState.replace("Ntt", PG_SEARCH_TERM);
        const searchParams = new URLSearchParams(navState);
        if (categoryId) {
          searchParams.set("categoryId", categoryId);
        }
        navState = searchParams.toString();
      }

      if (
        activeTab &&
        yn(env.FEATURE_SEARCH_ALL_BRANDS) &&
        pathname?.includes("/search/")
      ) {
        const searchParams = new URLSearchParams(navState);
        searchParams.set("site", activeTab?.site!);
        searchParams.set("activeTab", activeTab?.tabNo!);
        navState = searchParams.toString();
      }

      if (params?.skipng === "true") {
        navState += "&skipng=true";
      }

      if (params?.fromCG === "true") {
        navState += "&fromCG=true";
      }

      navState = navState.replace(/Ntt=undefined&/, "");
      navState = removeNttSearchParams(navState);

      history.push({ search: navState });
    },
    [pathname, history, categoryId, activeTab]
  );

  const handleActiveRefinement = useCallback(value => {
    setActiveRefinement(value);
  }, []);

  const handleDrawer = useCallback(value => {
    setDrawerOpen(value);
  }, []);

  const facetlength =
    (booleanFacet || []).filter(e => e?.refinements?.length !== 0).length +
    Object.keys(facetData).filter(
      e => facetData?.[e]?.[0]?.refinements?.length !== 0
    ).length;

  const moreFacetData = useMemo(() => {
    let allFacetData = Object.entries(facetData);
    return allFacetData;
  }, [facetData, facetlength, booleanFacet]);

  const breakpoints: Breakpoint[] = [
    { name: "between1201And1269", range: [1201, 1269] },
    { name: "between1269And1312", range: [1269, 1312] },
    { name: "between1312And1450", range: [1312, 1450] },
    { name: "between1450And1520", range: [1450, 1520] },
    { name: "between1520And1682", range: [1520, 1682] },
    { name: "between1682And1775", range: [1682, 1775] },
    { name: "between1775And1875", range: [1775, 1875] }
  ];
  const mediaQueries: Record<string, boolean> = breakpoints.reduce(
    (acc, breakpoint) => {
      acc[breakpoint.name] = useMediaQuery<Theme>(theme =>
        theme.breakpoints.between(...breakpoint.range)
      );
      return acc;
    },
    {}
  );

  const totalFacetlength = facetlength;

  const isMoreFacetEnable = useMemo(() => {
    const mediaQueryRanges = [
      {
        range:
          mediaQueries?.between1775And1875 || mediaQueries?.between1682And1775,
        minFacetLength: isNextGen ? 10 : 11
      },
      { range: mediaQueries?.between1520And1682, minFacetLength: 10 },
      { range: mediaQueries?.between1450And1520, minFacetLength: 9 },
      { range: mediaQueries?.between1312And1450, minFacetLength: 8 },
      { range: mediaQueries?.between1269And1312, minFacetLength: 7 },
      { range: mediaQueries?.between1201And1269, minFacetLength: 6 }
    ];

    const matches = mediaQueryRanges.filter(
      ({ range, minFacetLength }) => range && totalFacetlength > minFacetLength
    );

    return matches.length > 0;
  }, [totalFacetlength, mediaQueries]);

  const updateStatusMessage = message => {
    const statusMessageElement = document.getElementById("statusMessage");
    if (statusMessageElement) {
      statusMessageElement.textContent = message;
    }
  };

  return (
    <Grid
      id={"component-refine-menu-dropdown"}
      container
      direction="row"
      justify={
        (processEnvServer && !mobile) ||
        (mdUp && !between && facetlength <= 6) ||
        (mdUp && !between) ||
        (mdUp && between && facetlength <= 6)
          ? "flex-start"
          : "flex-end"
      }
      alignItems="center"
      ref={scrollableContainer}
      className={classes.scrollXContainer}
      onScroll={hideHint}
    >
      {(processEnvServer && !mobile) ||
      (mdUp && !between && facetlength <= 6) ||
      (mdUp && !between) ||
      (mdUp && between && facetlength <= 6) ? (
        <>
          <div
            className={`${
              isMoreFacetEnable && !isNextGen ? "w-full" : "w-auto"
            } flex flex-wrap h-6 overflow-hidden items-center`}
            tabIndex={0}
            role="group"
            aria-label={pageContent?.Filter_by || Filter_by}
          >
            <BrandsRefinement
              resultTabs={resultTabs || []}
              pathname={pathname}
            />
            {booleanFacet?.some(item => item?.refinements?.length) &&
              booleanFacet?.map((item, index) => {
                // commented to fix display of sale filter when sale = true in url
                // const isSaleRefinement =
                //   isSale && `${item?.refinements?.[0]?.label}` === "Sale";
                // if (hasShellProducts) return null;

                return item?.refinements?.length ? (
                  <Grid
                    item
                    key={`${item?.refinements?.[0]?.label}-${index}`}
                    className={classNames(
                      classes.booleanRefinement,
                      `${facetlength > 7 ? "!mr-5" : "!mr-10"} lg:!mr-10`
                    )}
                  >
                    <RefinementOptionData
                      refinement={item}
                      changeNavState={changeNavState}
                      labelStyle={{
                        textTransform: "uppercase",
                        color:
                          item?.refinements?.[0]?.label?.toLowerCase() ===
                          "sale"
                            ? "#CA2022"
                            : "initial",
                        fontSize: 11
                      }}
                      labelClassName={classNames(
                        typographyStyles.rhBaseCaption
                      )}
                    />
                  </Grid>
                ) : null;
              })}
            {moreFacetData.map(([facetName, facetRefinements], index) => {
              facetRefinements = facetRefinements.filter(
                r => r?.refinements?.length! > 1
              );
              const isSaleRefinement = facetName === "Sale";
              const isBooleanRefinement =
                (facetRefinements?.[0]?.refinements?.length ?? 0) === 0 && 1;

              if (isSaleRefinement && hasShellProducts) return null;

              const active = activeRefinement === facetName;

              if (
                index >= moreFacetData.length - booleanFacet?.length! &&
                isNextGen &&
                isMoreFacetEnable
              ) {
                return null;
              }

              return (
                <>
                  {!isBooleanRefinement && (
                    <Grid item key={`${facetName}-${index}`}>
                      <button
                        className={`relative top-[1px] flex items-center cursor-pointer bg-transparent px-[1px] py-1.5 border-none mr-[30px] lg:!mr-10 xl:mr-[60px] ${
                          facetlength > 7 ? "mr-5" : "mr-10"
                        }`}
                        onClick={() => {
                          setActiveRefinement(facetName);
                          refAnchors.current[index]?.focus();
                        }}
                        ref={el => (refAnchors.current[index] = el)}
                        aria-label={facetName}
                        aria-expanded={active}
                      >
                        <Typography
                          className={classNames([
                            typographyStyles.rhBaseCaption,
                            classes.refinementGroupTitle,
                            "select-none whitespace-nowrap uppercase  !text-[11px] !leading-[13px] !tracking-[0.04em]"
                          ])}
                          aria-hidden={true}
                        >
                          {facetName}
                        </Typography>
                        <KeyboardArrowDown
                          className={`text-darkGray-500 active:rotate-180 ${
                            active && "active"
                          } text-xs`}
                        />
                      </button>
                    </Grid>
                  )}
                  <DefaulPopper
                    id={`facet-${index}`}
                    open={active}
                    anchorEl={refAnchors.current[index]}
                    placement="bottom-start"
                    modifiers={{
                      flip: {
                        enabled: false
                      },
                      preventOverflow: {
                        enabled: false
                      }
                    }}
                    className="py-10 px-8 pt-2 -mt-5 shadow-popperBox min-w-[300px] max-h-[650px] overflow-y-auto z-[9999] bg-white-300"
                    tabIndex={0}
                    role="combobox"
                    disablePortal={true}
                  >
                    <ClickAwayListener
                      onClickAway={() => {
                        handleActiveRefinement(null);
                      }}
                    >
                      <div>
                        {FEATURE_TAILWIND_COMPONENTS ? (
                          <TailwindAccordionSummary
                            onClick={() => {
                              setActiveRefinement(null);
                            }}
                            expandIcon={
                              <KeyboardArrowDown
                                className={`text-darkGray-500 active:rotate-180 !text-[14px] !-mr-1.5 ${
                                  active && "active"
                                }`}
                                aria-label={
                                  pageContent?.Close_Menu ?? CLOSE_MENU
                                }
                                role="button"
                              />
                            }
                            classes={{
                              root: "p-0 bg-[#F9F7F4]",
                              content: "my-[32px] md:my-[21px]",
                              contentExpanded: "!mb-[34px]"
                            }}
                          >
                            <button
                              className="bg-transparent border-none"
                              aria-label={`${facetName} expanded`}
                              tabIndex={-1}
                            >
                              <Typography
                                className={classNames([
                                  typographyStyles.rhBaseCaption,
                                  classes.refinementGroupTitle,
                                  "select-none whitespace-nowrap uppercase  !text-[11px] !leading-[13px] !tracking-[0.04em] !m-[-3px]"
                                ])}
                                tabIndex={-1}
                              >
                                {facetName}
                              </Typography>
                            </button>
                          </TailwindAccordionSummary>
                        ) : (
                          <AccordionSummary
                            onClick={() => {
                              setActiveRefinement(null);
                            }}
                            tabIndex={0}
                            expandIcon={
                              <KeyboardArrowDown
                                className={`text-darkGray-500 active:rotate-180 !text-[14px] !-mr-1.5 ${
                                  active && "active"
                                }`}
                                aria-label={
                                  pageContent?.Close_Menu ?? CLOSE_MENU
                                }
                                role="button"
                              />
                            }
                            classes={classesAccordionSummary}
                          >
                            <button
                              className="bg-transparent border-none"
                              aria-label={`${facetName} expanded`}
                              tabIndex={-1}
                            >
                              <Typography
                                className={classNames([
                                  typographyStyles.rhBaseCaption,
                                  classes.refinementGroupTitle,
                                  "select-none whitespace-nowrap uppercase  !text-[11px] !leading-[13px] !tracking-[0.04em] !m-[-3px]"
                                ])}
                                tabIndex={-1}
                              >
                                {facetName}
                              </Typography>
                            </button>
                          </AccordionSummary>
                        )}

                        {facetRefinements.length === 1 ? (
                          <RefinementOptionData
                            refinement={facetRefinements[0]}
                            changeNavState={changeNavState}
                          />
                        ) : (
                          facetRefinements.map(r => (
                            <>
                              {FEATURE_TAILWIND_COMPONENTS ? (
                                <TailwindAccordion
                                  key={r.dimensionName}
                                  classes={{
                                    root: "relative last:border-0 last:border-b-[0.5px] last:border-solid last:border-gray-300 "
                                  }}
                                >
                                  <TailwindAccordionSummary
                                    expandIcon={
                                      <ExpandMoreIcon
                                        className={`text-[#808080] text-[14px]`}
                                      />
                                    }
                                    classes={{
                                      root: "p-0 bg-[#F9F7F4] relative before:content-[''] before:-top-[1px] before:left-0 before:right-0 before:h-[1px] before:opacity-100 transition-all before:absolute before:bg-[rgba(0,0,0,0.12)]",
                                      content: "my-[32px] md:my-[21px]",
                                      contentExpanded: "!mb-[34px]"
                                    }}
                                  >
                                    <button
                                      className={buttonStyles.unstyledBtn}
                                      aria-label={String(r.name)}
                                      tabIndex={-1}
                                    >
                                      <Typography
                                        className={classNames(
                                          typographyStyles.rhBaseCaption,
                                          "uppercase !text-[11px] !leading-[13px] !tracking-[0.04em]"
                                        )}
                                      >
                                        {r.name}
                                      </Typography>
                                    </button>
                                  </TailwindAccordionSummary>
                                  <TailwindAccordionDetails
                                    classes={{
                                      content:
                                        "block bg-[#f9f7f4] pb-[32px] [&>div]:p-0"
                                    }}
                                  >
                                    <RefinementOptionData
                                      refinement={r}
                                      changeNavState={changeNavState}
                                    />
                                  </TailwindAccordionDetails>
                                </TailwindAccordion>
                              ) : (
                                <Accordion
                                  key={r.dimensionName}
                                  square
                                  elevation={0}
                                  classes={classesAccordion}
                                >
                                  <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls={`panel-${r.name}-content`}
                                    id={`panel1a-${r.name}-header`}
                                    classes={classesAccordionSummary}
                                  >
                                    <button
                                      className={buttonStyles.unstyledBtn}
                                      aria-label={String(r.name)}
                                      tabIndex={-1}
                                    >
                                      <Typography
                                        className={classNames(
                                          typographyStyles.rhBaseCaption,
                                          "uppercase !text-[11px] !leading-[13px] !tracking-[0.04em]"
                                        )}
                                      >
                                        {r.name}
                                      </Typography>
                                    </button>
                                  </AccordionSummary>
                                  <AccordionDetails
                                    classes={classesAccordionDetail}
                                  >
                                    <RefinementOptionData
                                      refinement={r}
                                      changeNavState={changeNavState}
                                    />
                                  </AccordionDetails>
                                </Accordion>
                              )}
                            </>
                          ))
                        )}
                      </div>
                    </ClickAwayListener>
                  </DefaulPopper>
                </>
              );
            })}
          </div>
          {isMoreFacetEnable && (
            <Typography
              data-testid="more-filters-button"
              className={classNames([
                "relative top-[1px] flex items-center cursor-pointer bg-transparent border-none mr-[30px] lg:!mr-10 xl:mr-[60px] underline uppercase",
                facetlength > 7 ? "!mr-5" : "!mr-10",
                typographyStyles.rhBaseCaption
              ])}
              onClick={() => handleDrawer(true)}
              tabIndex={0}
              onKeyDown={handleKeyboardPressWithEvent(() => handleDrawer(true))}
            >
              MORE FILTERS
            </Typography>
          )}
          <div aria-busy={isBusy ? "true" : "false"}>
            <div
              id="statusMessage"
              role="region"
              aria-live="assertive"
              className="absolute -left-[9999px]"
              aria-label="result"
              tabIndex={1}
            >
              {/* Initially empty until results are returned */}
            </div>
          </div>
          {(!loading || processEnvServer) && !isNewConceptTabs && (
            <Typography
              className={classNames([
                "relative top-[1px] flex items-center bg-transparent border-none mr-[30px] lg:!mr-10 xl:mr-[60px] !cursor-default !text-[11px] !leading-[13px] !tracking-[0.04em]",
                typographyStyles.rhBaseCaption
              ])}
            >
              {pageContent?.SEARCH_RESULTS} ({totalCount})
            </Typography>
          )}

          {showHint && (
            <Grid
              item
              className={classNames(
                "absolute top-1/2 right-0 transform -translate-y-1/2 flex justify-end z-2 !w-[100px] !bg-white-500 [&>button]:cursor-pointer [&>button]:flex [&>button]:flex-col [&>button]:justify-center [&>button]:items-center [&>button]:p-[6.5px] [&>button]:bg-white [&>button]:border [&>button]:border-grey-200"
              )}
            >
              <IconButton disableRipple onClick={() => {}}>
                <RHRArrowIcon className="!text-black !text-[8px]" />
              </IconButton>
            </Grid>
          )}
          <Portal container={() => selectedRefinementContainer!}>
            {Object.values(selectedRefineOptions).flat().length > 0 && (
              <div className="flex items-center pt-4 flex-wrap">
                <SelectedRefinementButtons
                  refinements={Object.values(selectedRefineOptions).flat()}
                  showResetRefinement={!!showResetRefinement}
                  resetRefinement={resetRefinement}
                  changeNavState={changeNavState}
                />
              </div>
            )}
          </Portal>
        </>
      ) : (
        <>
          <Typography
            className={classNames([
              typographyStyles.rhBaseLink1,
              classes.refinementGroupTitle,
              "select-none whitespace-nowrap !uppercase !underline "
            ])}
            onClick={() => handleDrawer(true)}
            tabIndex={0}
            onKeyDown={handleKeyboardPressWithEvent(() => handleDrawer(true))}
          >
            {pageContent?.FILTER}
          </Typography>
          {!between && (
            <Typography
              className={classNames([
                typographyStyles.rhBaseLink1,
                `${classes.refinementGroupTitle} sort`,
                `select-none whitespace-nowrap uppercase  sort`,
                "!underline !uppercase"
              ])}
              onClick={() => handleDrawer(true)}
              tabIndex={0}
              onKeyDown={handleKeyboardPressWithEvent(() => handleDrawer(true))}
            >
              {(processEnvServer && !mobile) ||
              (mdUp && !between && facetlength <= 6) ||
              (mdUp && !between) ||
              (mdUp && between && facetlength <= 6)
                ? pageContent?.SORT_BY
                : pageContent?.SORT}
            </Typography>
          )}
        </>
      )}
      <DrawerComponent
        transitionDuration={225}
        className={classes.drawerMenu}
        anchor="right"
        open={drawerOpen}
        PaperProps={{ className: "!w-[85%] sm:!w-[370px] md:!w-[440px]" }}
        onClose={() => handleDrawer(false)}
      >
        <Grid
          container
          direction="row"
          justify="flex-end"
          className="h-14 sticky top-0 z-10 px-6 py-4"
        >
          <Grid item onClick={() => handleDrawer(false)}>
            <IconButton
              disableRipple
              className={classNames(buttonStyles.unstyledBtn, "p-0")}
            >
              <RHCloseIconUpdated className="!text-[20px]" />
            </IconButton>
          </Grid>
        </Grid>

        {loading && (
          <div className={`flex justify-center h-32 relative align-top`}>
            <RHSpinner />
          </div>
        )}

        {!loading && (
          <div className="overflow-auto px-6 sm:px-[66px] py-0 mt-[4px]">
            <BrandsRefinement
              resultTabs={resultTabs || []}
              pathname={pathname}
            />
            <div className="[&>*:first-child]:pt-0 [&>*:first-child]:border-0 [&>*:nth-child(2)]:border-0">
              {booleanFacet?.some(item => item?.refinements?.length) &&
                booleanFacet?.map((item, index) => {
                  const isSaleRefinement =
                    isSale && `${item?.refinements?.[0]?.label}` === "Sale";
                  if (isSaleRefinement || hasShellProducts) return null;

                  return item?.refinements?.length ? (
                    <Grid
                      item
                      key={`${item?.refinements?.[0]?.label}-${index}`}
                      className={classNames(
                        classes.mobileBooleanRefinement,
                        "block border-b pb-8 border-[#0000001f]",
                        isSaleRefinement && "pt-0"
                      )}
                    >
                      <RefinementOptionData
                        refinement={item}
                        changeNavState={changeNavState}
                        labelStyle={{
                          textTransform: "uppercase",
                          color:
                            item?.refinements?.[0]?.label?.toLowerCase() ===
                            "sale"
                              ? "#CA2022"
                              : "initial",
                          fontSize: 11
                        }}
                        labelClassName={classNames(
                          typographyStyles.rhBaseCaption
                        )}
                      />
                    </Grid>
                  ) : null;
                })}
            </div>
            {Object.entries(facetData).map(
              ([facetName, facetRefinements], index) => {
                facetRefinements = facetRefinements.filter(
                  r => r?.refinements?.length! > 1
                );
                const isBooleanRefinement =
                  (facetRefinements?.[0]?.refinements?.length ?? 0) === 0 && 1;
                const hasSelectedRefinements =
                  facetName !== "Sort" &&
                  selectedRefineOptions[facetName] &&
                  selectedRefineOptions[facetName].length > 0;

                return (
                  <>
                    {!isBooleanRefinement &&
                      (FEATURE_TAILWIND_COMPONENTS ? (
                        <TailwindAccordion
                          classes={{
                            root: "relative last:border-0 last:border-b-[0.5px] last:border-solid last:border-gray-300 "
                          }}
                          key={`facet-accordion-${index}`}
                        >
                          <TailwindAccordionSummary
                            expandIcon={
                              <ExpandMoreIcon
                                className={`text-[#808080] text-[14px] ${
                                  hasSelectedRefinements ? "mt-[22px]" : "mt-0"
                                }`}
                              />
                            }
                            classes={{
                              root: `p-0 bg-white ${
                                hasSelectedRefinements && "!items-start"
                              } relative before:content-[''] before:-top-[1px] before:left-0 before:right-0 before:h-[1px] before:opacity-100 transition-all before:absolute before:bg-[rgba(0,0,0,0.12)]`,
                              content: "!my-6 md:my-[21px]",
                              contentExpanded: "!mb-[34px]"
                            }}
                          >
                            <button
                              tabIndex={-1}
                              className={buttonStyles.unstyledBtn}
                            >
                              <Typography
                                className={classNames(
                                  typographyStyles.rhBaseCaption,
                                  "!uppercase !text-[11px] !leading-3 !tracking-[0.04em]"
                                )}
                              >
                                {facetName}
                              </Typography>
                              {hasSelectedRefinements && (
                                <div className="mt-3.5 flex items-center flex-wrap">
                                  <SelectedRefinementButtons
                                    refinements={
                                      selectedRefineOptions[facetName]
                                    }
                                    showResetRefinement={false}
                                    changeNavState={changeNavState}
                                  />
                                </div>
                              )}
                            </button>
                          </TailwindAccordionSummary>

                          <TailwindAccordionDetails
                            classes={{
                              content: "block bg-white pb-[32px] [&>div]:p-0"
                            }}
                          >
                            {facetRefinements.map(r => (
                              <div
                                className="mb-9 last:mb-0 md:mb-[29px]"
                                key={r.dimensionName}
                              >
                                {facetRefinements.length > 1 && (
                                  <Typography
                                    className={classNames(
                                      typographyStyles.rhBaseCaption,
                                      "!mb-6"
                                    )}
                                  >
                                    {r.name}
                                  </Typography>
                                )}
                                <RefinementOptionData
                                  refinement={r}
                                  changeNavState={changeNavState}
                                />
                              </div>
                            ))}
                          </TailwindAccordionDetails>
                        </TailwindAccordion>
                      ) : (
                        <Accordion
                          classes={classesAccordion}
                          square
                          elevation={0}
                          key={`facet-accordion-${index}`}
                        >
                          <AccordionSummary
                            classes={classesAccordionSummary}
                            className={`${
                              hasSelectedRefinements
                                ? "items-start"
                                : "items-center"
                            }`}
                            expandIcon={
                              <ExpandMoreIcon
                                className={`${
                                  hasSelectedRefinements ? "mt-[22px]" : "mt-0"
                                }`}
                              />
                            }
                            aria-controls={`panel-${facetName}-content`}
                            id={`panel1a-${facetName}-header`}
                            role="listbox"
                            aria-label={facetName}
                          >
                            <button
                              tabIndex={-1}
                              className={buttonStyles.unstyledBtn}
                            >
                              <Typography
                                className={classNames(
                                  typographyStyles.rhBaseCaption,
                                  "!uppercase !text-[11px] !leading-3 !tracking-[0.04em]"
                                )}
                              >
                                {facetName}
                              </Typography>
                              {hasSelectedRefinements && (
                                <div className="mt-3.5 flex items-center flex-wrap">
                                  <SelectedRefinementButtons
                                    refinements={
                                      selectedRefineOptions[facetName]
                                    }
                                    showResetRefinement={false}
                                    changeNavState={changeNavState}
                                  />
                                </div>
                              )}
                            </button>
                          </AccordionSummary>

                          <AccordionDetails
                            role="listbox"
                            classes={classesAccordionDetail}
                          >
                            {facetRefinements.map(r => (
                              <div
                                className="mb-9 last:mb-0 md:mb-[29px]"
                                key={r.dimensionName}
                              >
                                {facetRefinements.length > 1 && (
                                  <Typography
                                    className={classNames(
                                      typographyStyles.rhBaseCaption,
                                      "!mb-6"
                                    )}
                                  >
                                    {r.name}
                                  </Typography>
                                )}
                                <RefinementOptionData
                                  refinement={r}
                                  changeNavState={changeNavState}
                                />
                              </div>
                            ))}
                          </AccordionDetails>
                        </Accordion>
                      ))}
                  </>
                );
              }
            )}
          </div>
        )}
        <div className="mt-auto w-full px-6 pt-6 pb-8 flex !bg-white-300 justify-center">
          <Button
            id="refineMenuDropdown_clearAll-btn"
            data-testid="refineMenuDropdown_clearAll-btn"
            className={classNames(
              buttonStyles.secondaryBtn,
              "w-[125.5px] mr-3"
            )}
            disabled={loading}
            onClick={e => resetRefinement?.(e)}
          >
            {pageContent?.CLEAR_ALL}
          </Button>
          <Button
            id="refineMenuDropdown_view-btn"
            className={classNames(buttonStyles.primaryBlackBtn, "w-[125.5px]")}
            onClick={() => handleDrawer(false)}
            disabled={loading}
          >
            {pageContent?.VIEW} {!loading && totalCount}
          </Button>
        </div>
      </DrawerComponent>
    </Grid>
  );
};

RefineSearchMenu.defaultProps = {
  refinementMenus: [],
  sortMenu: [],
  totalCount: 0,
  resultTabs: []
};

export default memoize(RefineSearchMenu);
