import React, { useState, useRef, useEffect, CSSProperties } from "react";
import classNames from "classnames";
import memoize from "utils/memoize";

interface Modifiers {
  flip?: {
    enabled: boolean;
  };
  arrow?: {
    enabled: boolean;
    arrowIcon?: React.ReactNode;
    element?: React.RefObject<HTMLDivElement>;
  };
  preventOverflow?: {
    enabled: boolean;
  };
  offset?: {
    enabled: boolean;
    offset: string;
  };
}

interface TailwindPopperProps {
  anchorEl: HTMLElement | null;
  open: boolean;
  children: React.ReactNode;
  placement?:
    | "top"
    | "right"
    | "bottom"
    | "left"
    | "bottom-end"
    | "bottom-start"
    | "top-start"
    | string;
  className?: string;
  disablePortal?: boolean;
  modifiers?: Modifiers;
  style?: React.CSSProperties;
  id?: string;
}

const parseOffset = (offset: string) => {
  const [x, y] = offset.split(",").map(value => parseInt(value.trim(), 10));
  return { x: isNaN(x) ? 0 : x, y: isNaN(y) ? 0 : y };
};

const getPopperStyles = (
  placement: TailwindPopperProps["placement"],
  anchorEl: HTMLElement,
  popperEl: HTMLElement,
  offset: { x: number; y: number } = { x: 0, y: 0 }
): CSSProperties => {
  const styles: CSSProperties = { position: "absolute" };

  const anchorTop = anchorEl?.offsetTop;
  const anchorLeft = anchorEl?.offsetLeft;
  const anchorHeight = anchorEl?.offsetHeight;
  const anchorWidth = anchorEl?.offsetWidth;
  const popperHeight = popperEl?.offsetHeight;
  const popperWidth = popperEl?.offsetWidth;

  switch (placement) {
    case "top":
      styles.top = `${anchorTop - popperHeight + offset?.y}px`;
      styles.left = `${anchorLeft + offset.x}px`;
      break;
    case "right":
      styles.top = `${anchorTop + offset.y}px`;
      styles.left = `${anchorLeft + anchorWidth + offset?.x}px`;
      break;
    case "bottom":
      styles.top = `${anchorTop + anchorHeight + offset?.y}px`;
      styles.left = `${anchorLeft + offset.x}px`;
      break;
    case "left":
      styles.top = `${anchorTop + offset.y}px`;
      styles.left = `${anchorLeft - popperWidth + offset?.x}px`;
      break;
    case "top-start":
      styles.top = `${anchorTop - popperHeight + offset?.y}px`;
      styles.left = `${anchorLeft + offset.x}px`;
      break;
    case "top-end":
      styles.top = `${anchorTop - popperHeight + offset?.y}px`;
      styles.left = `${anchorLeft + anchorWidth - popperWidth + offset?.x}px`;
      break;
    case "bottom-end":
      styles.top = `${anchorTop + anchorHeight + offset?.y}px`;
      styles.left = `${anchorLeft + anchorWidth - popperWidth + offset?.x}px`;
      break;
    case "bottom-start":
      styles.top = `${anchorTop + anchorHeight + offset?.y}px`;
      styles.left = `${anchorLeft + offset?.x}px`;
      break;
    default:
      break;
  }

  return styles;
};

const TailwindPopper: React.FC<TailwindPopperProps> = ({
  anchorEl,
  open,
  children,
  placement = "bottom",
  className,
  style = {},
  modifiers = {},
  ...rest
}) => {
  const [popperStyles, setPopperStyles] = useState<CSSProperties>({});
  const popperRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (anchorEl && popperRef.current) {
      const offset = modifiers?.offset?.enabled
        ? parseOffset(modifiers?.offset?.offset)
        : { x: 0, y: 0 };

      const styles = getPopperStyles(
        placement,
        anchorEl,
        popperRef.current,
        offset
      );
      setPopperStyles(styles);
    }
  }, [anchorEl, placement, open, modifiers?.offset]);

  const popperContent = (
    <div
      ref={popperRef}
      className={classNames(className, "z-40 bg-[#f9f7f4] shadow-md absolute")}
      style={{ ...style, ...popperStyles }}
      tabIndex={-1}
      data-testid={"component-tailwind-popper"}
      x-placement={placement}
      {...rest}
    >
      {children}
      {modifiers?.arrow?.enabled && modifiers?.arrow?.arrowIcon}
    </div>
  );

  return open ? popperContent : null;
};

export default memoize(TailwindPopper);
