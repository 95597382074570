import React, { CSSProperties, ReactNode } from "react";
import memoize from "utils/memoize";

interface AccordionSummaryProps {
  onClick?: () => void;
  children: ReactNode;
  isOpen?: boolean;
  expandIcon?: ReactNode;
  classes?: { [key: string]: string };
  style?: CSSProperties;
}

const AccordionSummary: React.FC<AccordionSummaryProps> = ({
  onClick,
  children,
  isOpen,
  expandIcon,
  classes,
  style = {},
  ...rest
}) => {
  return (
    <div
      className={`[-webkit-tap-highlight-color:transparent] flex justify-between items-center ${
        classes?.root ?? ""
      }
        ${isOpen && classes?.expanded}`}
      onClick={onClick}
      style={{
        transition:
          "min-height 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
        ...style
      }}
      {...rest}
    >
      <div
        className={`my-[12px] flex flex-grow ${classes?.content ?? ""} ${
          isOpen && (classes?.contentExpanded ?? "")
        }`}
        style={{ transition: "margin 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms" }}
      >
        {children}
      </div>
      {expandIcon && (
        <div
          className={`[-webkit-tap-highlight-color:transparent] flex-none inline-flex text-[rgba(0,0,0,0.54)] cursor-pointer p-[10px] mr-[-12px] overflow-visible text-[2.083rem] text-center transition-colors duration-150 ease-in-out rounded-full`}
        >
          <span
            className="w-full inline-flex"
            style={{
              transform: isOpen ? "rotate(180deg)" : "rotate(0deg)",
              transition: "transform 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms"
            }}
          >
            {expandIcon}
          </span>
        </div>
      )}
    </div>
  );
};

export default memoize(AccordionSummary);
