import { PropTypes } from "aem/ComponentWrapper";
import { getContent } from ".";

export default {
  title: "Text",
  type: "rh/components/content/text",
  superType: "core/wcm/components/text/v2/text",
  config: {
    emptyLabel: "Empty Text",
    isEmpty: ({ text }: PropTypes) => !!!getContent(text)?.trim?.()
  }
};
