import { getAEMCommonHTMLAttributes } from "aem";
import { AEMComponentProps } from "aem/types";
import classNames from "classnames";
import { getContent, TextContent } from "component-text";
import he from "he";
import React from "react";
import { RHReferenceableComponentProps } from "types";
import { RHAnimatableComponentProps } from "utils/animation/types";
import { useAnimation } from "utils/animation/useAnimation";
import {
  createStyles,
  makeStyles,
  Theme,
  useMediaQuery
} from "utils/material-ui-core";

export type TextCustomProps = React.HTMLAttributes<HTMLDivElement> &
  RHAnimatableComponentProps &
  RHReferenceableComponentProps & {
    textColorXS?: string;
    textColorSM?: string;
    textColorMD?: string;
    textColorLG?: string;
    textColorXL?: string;
    alignmentXS?: string;
    alignmentSM?: string;
    alignmentMD?: string;
    alignmentLG?: string;
    alignmentXL?: string;
    lineHeightXS?: string;
    lineHeightSM?: string;
    lineHeightMD?: string;
    lineHeightLG?: string;
    lineHeightXL?: string;
    styleXS?: string;
    styleSM?: string;
    styleMD?: string;
    styleLG?: string;
    styleXL?: string;
    transformXS?: string;
    transformSM?: string;
    transformMD?: string;
    transformLG?: string;
    transformXL?: string;
    weightXS?: string | number;
    weightSM?: string | number;
    weightMD?: string | number;
    weightLG?: string | number;
    weightXL?: string | number;
    fontXS?: string;
    fontSM?: string;
    fontMD?: string;
    fontLG?: string;
    fontXL?: string;
    spacingXS?: string;
    spacingSM?: string;
    spacingMD?: string;
    spacingLG?: string;
    spacingXL?: string;
    sizeXS?: string;
    sizeSM?: string;
    sizeMD?: string;
    sizeLG?: string;
    sizeXL?: string;
    hideXS?: boolean;
    hideSM?: boolean;
    hideMD?: boolean;
    hideLG?: boolean;
    hideXL?: boolean;
    textXS?: TextContent;
    textSM?: TextContent;
    textMD?: TextContent;
    textLG?: TextContent;
    textXL?: TextContent;
    isV3?: boolean;
  };

const useStyles = (props: TextCustomProps) =>
  makeStyles((theme: Theme) => {
    const styles: any = {};
    if (props.isV3 ? props.alignmentXS : props.textXS) {
      styles[theme.breakpoints.up("xs")] = {
        fontFamily: props.fontXS,
        lineHeight: props.lineHeightXS,
        fontStyle: props.styleXS,
        textTransform: props.transformXS,
        fontWeight: props.weightXS,
        textAlign: props.alignmentXS,
        color: props.textColorXS,
        fontSize: props.sizeXS,
        letterSpacing: props.spacingXS
      };
    }
    if (props.isV3 ? props.alignmentSM : props.textSM) {
      styles[
        props.isV3
          ? theme.breakpoints.between("sm", "md")
          : theme.breakpoints.up("sm")
      ] = {
        fontFamily: props.fontSM,
        lineHeight: props.lineHeightSM,
        fontStyle: props.styleSM,
        textTransform: props.transformSM,
        fontWeight: props.weightSM,
        textAlign: props.alignmentSM,
        color: props.textColorSM,
        fontSize: props.sizeSM,
        letterSpacing: props.spacingSM
      };
    }
    if (props.isV3 ? props.alignmentMD : props.textMD) {
      styles[
        props.isV3
          ? theme.breakpoints.between("md", "lg")
          : theme.breakpoints.up("md")
      ] = {
        fontFamily: props.fontMD,
        lineHeight: props.lineHeightMD,
        fontStyle: props.styleMD,
        textTransform: props.transformMD,
        fontWeight: props.weightMD,
        textAlign: props.alignmentMD,
        color: props.textColorMD,
        fontSize: props.sizeMD,
        letterSpacing: props.spacingMD
      };
    }
    if (props.isV3 ? props.alignmentLG : props.textLG) {
      styles[
        props.isV3
          ? theme.breakpoints.between("lg", "xl")
          : theme.breakpoints.up("lg")
      ] = {
        fontFamily: props.fontLG,
        lineHeight: props.lineHeightLG,
        fontStyle: props.styleLG,
        textTransform: props.transformLG,
        fontWeight: props.weightLG,
        textAlign: props.alignmentLG,
        color: props.textColorLG,
        fontSize: props.sizeLG,
        letterSpacing: props.spacingLG
      };
    }
    if (props.isV3 ? props.alignmentXL : props.textXL) {
      styles[theme.breakpoints.up("xl")] = {
        fontFamily: props.fontXL,
        lineHeight: props.lineHeightXL,
        fontStyle: props.styleXL,
        textTransform: props.transformXL,
        fontWeight: props.weightXL,
        textAlign: props.alignmentXL,
        color: props.textColorXL,
        fontSize: props.sizeXL,
        letterSpacing: props.spacingXL
      };
    }
    return createStyles({
      RHTextCustomClass: styles
    });
  });

export const TextCustom: React.FC<TextCustomProps> = ({
  domRefCallback,
  extraHTMLAttributes = {},
  rhUid,
  rhAnimation,
  ...props
}) => {
  useAnimation({ rhUid, rhAnimation });

  const classes = useStyles(props)();
  const upSM = useMediaQuery<Theme>(theme => theme.breakpoints.up("sm"));
  const upMD = useMediaQuery<Theme>(theme => theme.breakpoints.up("md"));
  const upLG = useMediaQuery<Theme>(theme => theme.breakpoints.up("lg"));
  const upXL = useMediaQuery<Theme>(theme => theme.breakpoints.up("xl"));
  const {
    className,
    textXS,
    textMD,
    textSM,
    textLG,
    textXL,
    hideXS,
    hideSM,
    hideMD,
    hideLG,
    hideXL
  } = props;

  return upXL ? (
    hideXL ? null : (
      <div
        id={props?.id || "component-text-custom"}
        {...extraHTMLAttributes}
        ref={domRefCallback}
        className={classNames(
          extraHTMLAttributes?.className,
          classes.RHTextCustomClass,
          className
        )}
        dangerouslySetInnerHTML={{
          __html: he.decode(
            getContent(
              textXL
                ? textXL
                : textLG
                ? textLG
                : textMD
                ? textMD
                : textSM
                ? textSM
                : textXS || ""
            )
          )
        }}
      />
    )
  ) : upLG ? (
    hideLG ? null : (
      <div
        id={props?.id || "component-text-custom"}
        {...extraHTMLAttributes}
        ref={domRefCallback}
        className={classNames(
          extraHTMLAttributes?.className,
          classes.RHTextCustomClass,
          className
        )}
        dangerouslySetInnerHTML={{
          __html: he.decode(
            getContent(
              textLG ? textLG : textMD ? textMD : textSM ? textSM : textXS || ""
            )
          )
        }}
      />
    )
  ) : upMD ? (
    hideMD ? null : (
      <div
        id={props?.id || "component-text-custom"}
        {...extraHTMLAttributes}
        ref={domRefCallback}
        className={classNames(
          extraHTMLAttributes?.className,
          classes.RHTextCustomClass,
          className
        )}
        dangerouslySetInnerHTML={{
          __html: he.decode(
            getContent(textMD ? textMD : textSM ? textSM : textXS || "")
          )
        }}
      />
    )
  ) : upSM ? (
    hideSM ? null : (
      <div
        id={props?.id || "component-text-custom"}
        {...extraHTMLAttributes}
        ref={domRefCallback}
        className={classNames(
          extraHTMLAttributes?.className,
          classes.RHTextCustomClass,
          className
        )}
        dangerouslySetInnerHTML={{
          __html: he.decode(getContent(textSM ? textSM : textXS || ""))
        }}
      />
    )
  ) : hideXS ? null : (
    <div
      id={props?.id || "component-text-custom"}
      {...extraHTMLAttributes}
      ref={domRefCallback}
      className={classNames(
        extraHTMLAttributes?.className,
        classes.RHTextCustomClass,
        className
      )}
      dangerouslySetInnerHTML={{
        __html: he.decode(getContent(textXS || ""))
      }}
    />
  );
};

TextCustom.defaultProps = {};

export type AEMTextCustomProps = AEMComponentProps & TextCustomProps;

export const AEMTextCustom: React.FC<AEMTextCustomProps> = props => {
  const aemCommonHTMLAttributes = getAEMCommonHTMLAttributes(props);

  const { children, ...rest } = props;

  return (
    <TextCustom
      id={rest?.id || "component-aem-text-custom"}
      {...rest}
      className={classNames(rest.className, rest.hideClassNames)}
      data-rte-editelement
      extraHTMLAttributes={aemCommonHTMLAttributes}
    />
  );
};

AEMTextCustom.defaultProps = {};

export default AEMTextCustom;
