/**
 * Reference: https://reactrouter.com/web/guides/scroll-restoration
 */

import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import memoize from "utils/memoize";

export default memoize(
  function ScrollManager() {
    const { pathname } = useLocation();

    useEffect(() => {
      window.scrollTo(0, 0);
    }, [pathname]);

    return null;
  },
  { deep: false }
);
