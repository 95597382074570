import { TailwindSkeleton as Skeleton } from "component-tailwind-skeletons";
import React from "react";

export const TwoColumnsSkeleton = () => {
  return (
    <div className="flex flex-wrap w-full">
      <div className="w-full md:w-1/2">
        <Skeleton width="100%" height="20rem" className={"my-0"} />
      </div>
      <div className="w-full md:w-1/2">
        <Skeleton width="100%" height="20rem" className={"my-0"} />
      </div>
    </div>
  );
};
