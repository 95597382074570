import AEMWrapper from "aem/ComponentWrapper";
import loadable from "@loadable/component";
import meta from "./aem-meta";
import React from "react";

const Component = loadable(
  () =>
    import(
      /* webpackChunkName: "component-virtual-agent" */ "./VirtualAgentButton"
    ),
  {
    fallback: <></>
  }
);

export default AEMWrapper(meta, Component);
