import RHCheckboxIcon from "icons/RHCheckboxIcon";
import React from "react";
import { cn } from "utils/cn";
import memoize from "utils/memoize";

export interface TailwindCheckboxProps
  extends React.HtmlHTMLAttributes<HTMLInputElement> {
  checked: any;
  checkedIcon?: React.ReactNode;
  color?: "primary" | "secondary" | "default";
  disabled?: boolean;
  disableRipple?: boolean;
  icon?: React.ReactNode;
  id?: string;
  indeterminate?: boolean;
  indeterminateIcon?: React.ReactNode;
  inputProps?: React.Ref<HTMLInputElement>;
  inputRef?: React.Ref<HTMLInputElement>;
  onChange?: (e) => void;
  required?: boolean;
  size?: "small" | "medium";
  value?: string;
  iconType?: string;
  checkboxLabelClass?: string;
}
const TailwindCheckbox = props => {
  const {
    checked,
    id = "tailwind-checkbox",
    inputProps,
    iconType,
    ...restProps
  } = props;
  return (
    <label
      id={`tailwind-${id}`}
      data-testid={`tailwind-${id}`}
      className={`relative flex items-center ${
        !iconType ? "p-3" : ""
      } rounded-full cursor-pointer justify-center ${
        restProps?.checkboxLabelClass
      }`}
    >
      <input
        {...restProps}
        {...inputProps}
        type="checkbox"
        id={`tailwind-${id}`}
        checked={checked}
        value={String(!!checked)}
        className={`before:content[''] z-10 peer relative h-8 w-8 cursor-pointer appearance-none  border ${
          restProps?.icon ? "border-gray-900/20" : "border-gray-900"
        } bg-transparent transition-all before:absolute before:top-2/4 before:left-2/4 before:block before:h-12 before:w-12 before:-translate-y-2/4 before:-translate-x-2/4  before:bg-blue-gray-500 before:opacity-0 before:transition-opacity checked:border-gray-red  checked:before:bg-[#666666] hover:scale-105 hover:before:opacity-0 ${
          restProps?.className
        }`}
      />
      {restProps?.icon ?? iconType ? (
        iconType === "block" ? (
          <div
            className={cn(
              `h-3 w-3 absolute mr-[6px]  ${
                checked ? "bg-black" : "bg-transparent"
              }`
            )}
            style={{ boxShadow: "inset 0 0 0px 2.5px #F9F7F4" }}
          />
        ) : (
          <RHCheckboxIcon checked={!!checked} className="absolute" />
        )
      ) : (
        checked && (
          <svg
            className="MuiSvgIcon-root text-[15px] checkmark absolute"
            focusable="false"
            viewBox="0 0 16 15"
            aria-hidden="true"
            style={{ fontSize: restProps?.size ? "12px" : "17px", zIndex: 10 }}
          >
            <path
              className="check"
              d="M1 7.5l5 5 9-9"
              fill="none"
              stroke="currentColor"
              stroke-width="1"
              stroke-linecap="round"
            ></path>
          </svg>
        )
      )}
    </label>
  );
};

export default memoize(TailwindCheckbox);
