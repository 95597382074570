import { PropTypes } from "aem/ComponentWrapper";

export default {
  group: "Data",
  title: "Static JSON",
  type: "rh/components/data/staticjsonprovider",
  config: {
    emptyLabel: "No data",
    isEmpty: (props: PropTypes) => !!!props.data
  }
};
