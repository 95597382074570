import { PropTypes } from "aem/ComponentWrapper";
export default {
  title: "Text Custom V3",
  type: "rh/components/content/textCustom3",
  superType: "core/wcm/components/text/v2/text",
  config: {
    emptyLabel: "Empty Text",
    isEmpty: (_: PropTypes) => false
  }
};
