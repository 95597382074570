import React, {
  FC,
  useState,
  ReactNode,
  CSSProperties,
  useCallback
} from "react";
import memoize from "utils/memoize";

interface AccordionProps {
  children: ReactNode;
  classes?: { [key: string]: string };
  style?: CSSProperties;
}

const Accordion: FC<AccordionProps> = ({ children, classes, style = {} }) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const onClick = useCallback(() => {
    setIsOpen(!isOpen);
  }, [isOpen]);

  return (
    <div
      className={` text-black/90 bg-[#f9f7f4] ${classes?.root ?? ""} ${
        isOpen && (classes?.expanded ?? "")
      }`}
      style={{
        transition: "margin 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
        ...style
      }}
    >
      {React.Children.map(children, child =>
        React.isValidElement(child)
          ? React.cloneElement(child as any, { isOpen, onClick })
          : child
      )}
    </div>
  );
};

export default memoize(Accordion);
